<template>
  <section v-bind:class="{ main: true, index: activeStep < 2 }">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Assessment</b></h1>
      </div>
      <div class="column is-11 bar">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Assessment Setup</h3>
            </li>
            <li>
              <router-link :to="{ name: `assessment` }">
                <h3 class="is-size-6">Assessment</h3>
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: `/assessment/show/${assessmentId}` }">
                <h3 class="is-size-6">Assessment Detail</h3>
              </router-link>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Section Detail</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-1 bar">
        <b-field grouped position="is-right">
          <router-link :to="{ name: `editAssessmentSection` }">
            <b-button class="is-hcc">Edit</b-button>
          </router-link>
        </b-field>
      </div>
    </div>
    <br />
    <br />

    <div class="columns">
      <div class="column is-12">
        <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :has-navigation="true"
        >
          <!-- For Introduction -->
          <b-step-item
            label="Introduction"
            icon="envelope"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <AssessmentIntroduction :data="introduction" />
          </b-step-item>

          <!-- For Rules -->
          <b-step-item
            label="Rules"
            icon="pencil-ruler"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <AssessmentRules :data="rules" />
          </b-step-item>

          <!-- For Introduction Test -->
          <b-step-item
            label="Introduction Test"
            icon="flag"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <AssessmentQuestion
              :data="sectionIntroductionTest"
              :rulesData="rules"
              :title="'Introduction Test'"
            />
          </b-step-item>

          <!-- For Question Test -->
          <b-step-item
            label="Question Test"
            icon="poll"
            :clickable="true"
            :type="{ 'is-hcc': isProfileSuccess }"
          >
            <AssessmentQuestion
              :data="sectionQuestionTest"
              :rulesData="rules"
              :title="'Question Test'"
            />
          </b-step-item>

          <!-- For pagination -->
          <template slot="navigation" slot-scope="{ previous, next }">
            <b-button
              type="is-hcc"
              class="prev"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              >Prev</b-button
            >

            <b-button
              type="is-hcc"
              @click.prevent="next.action"
              class="button-next"
              :disabled="next.disabled"
              >Next</b-button
            >
          </template>
        </b-steps>
      </div>
    </div>
  </section>
</template>

<script>
import AssessmentIntroduction from "./assessment-step-detail/AssessmentIntroduction";
import AssessmentRules from "./assessment-step-detail/AssessmentRules";
import AssessmentQuestion from "./assessment-step-detail/AssessmentQuestion";

export default {
  components: {
    AssessmentIntroduction,
    AssessmentRules,
    AssessmentQuestion
  },
  data() {
    return {
      // For Active Step
      activeStep: 0,

      // For Animated Step
      isAnimated: true,

      // When step is success
      isProfileSuccess: true,

      // FOr loading page
      isLoading: false,

      // For Assessment Id
      assessmentId: 0,

      // For Introduction
      introduction: {
        title: "",
        description: ""
      },

      // For Rules Data
      rules: {
        rulesName: "",
        time: 0,
        isUseTime: 0,
        questionType: "",
        checkboxTypeAnswer: "",
        checkboxMinAnswer: 0,
        checkboxMaxAnswer: 0,
        rankingTypeAnswer: "",
        rankingMinAnswer: 0,
        rankingMaxAnswer: 0,
        randomQuestionNumber: 0,
        randomType: "Random Question",
        rankingPointCategory: [
          {
            title: "",
            point: 0
          }
        ],
        typePoint: []
      },

      // For Section Introduction Test
      sectionIntroductionTest: {
        assessment: [],
        section: [],
        introduction: "",
        listEditedPhotoQuestion: [],
        listEditedPhotoOption: [],
        deletedQuestion: []
      },

      // For Section Question Test
      sectionQuestionTest: {
        assessment: [],
        section: [],
        listEditedPhotoQuestion: [],
        listEditedPhotoOption: [],
        deletedQuestion: []
      },

      // For Assessment Introduction Test
      assessmentIntroductionTest: {
        assessment: [],
        introduction: ""
      },

      // For Assessment Question Test
      assessmentQuestionTest: {
        assessment: []
      }
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // For get assessment detail data
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getAssessmentSection",
        this.$route.params.assessmentSectionId
      );
      this.introduction = response.introduction;
      this.rules = response.rules;
      this.sectionIntroductionTest.section = response.introductionGroup;
      this.sectionIntroductionTest.introduction =
        response.introductionPreviewTest;
      this.sectionQuestionTest.section = response.questionGroup;
      this.assessmentId = response.assessmentId;

      this.rules.typePoint = JSON.parse(this.rules.typePoint);
      this.rules.rankingPointCategory = JSON.parse(
        this.rules.rankingPointCategory
      );

      this.isLoading = false;
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>
