<template>
  <div>
    <!-- For Text -->
    {{ data.question }}

    <br />

    <div v-html="data.questionYoutubeLink"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
