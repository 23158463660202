<template>
  <div>
    <!-- For title assessment -->
    <h1 class="title has-text-centered">{{ title }}</h1>

    <!-- For button preview -->
    <div class="columns">
      <div class="column is-12">
        <b-button
          type="is-hcc"
          icon-left="eye"
          @click="isModalPreviewActive = true"
          >PREVIEW</b-button
        >
      </div>
    </div>

    <!-- For List Assessment -->
    <div class="box question" v-if="title == 'Introduction Test'">
      <div class="columns is-12">
        <div class="column is-2">
          <b>Introduction</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div v-html="data.introduction"></div>
        </div>
      </div>
    </div>

    <span
      v-for="(section, indexSection) in data.section"
      :key="indexSection"
      class="section"
    >
      <div>
        <div class="box custom-header" type="is-hcc">
          <span class="columns">
            <div class="column is-12 custom-header-label">
              <p>Question Group {{ indexSection + 1 }}</p>
            </div>
          </span>
        </div>
        <template v-if="section.isUseQuestion">
          <div class="box question">
            <div class="columns is-12">
              <!-- For title question -->
              <div class="column is-2">
                <span class="handle">
                  <i class="glyphicon glyphicon-menu-hamburger"></i>
                </span>
                <b>Question</b>
              </div>

              <!-- For type question -->
              <div class="column is-10">
                <div class="columns is-12">
                  <div class="column is-8">
                    <!-- For Text Type -->
                    <template v-if="section.questionType == 'text'">
                      <QuestionTypeText :data="section" />
                    </template>

                    <!-- For Image Type -->
                    <template v-else-if="section.questionType == 'image'">
                      <QuestionTypeImage :data="section" />
                    </template>

                    <!-- For Video Type -->
                    <template v-else-if="section.questionType == 'video'">
                      <QuestionTypeVideo :data="section" />
                    </template>

                    <!-- For Free Text Type -->
                    <template v-if="section.questionType == 'free_text'">
                      <QuestionTypeFreeText :data="section" />
                    </template>

                    <!-- For Youtube Link Type -->
                    <template v-else-if="section.questionType == 'youtube'">
                      <QuestionTypeYoutubeLink :data="section" />
                    </template>

                    <!-- For Image And Text Type -->
                    <template v-else-if="section.questionType == 'text_image'">
                      <QuestionTypeTextImage :data="section" />
                    </template>

                    <!-- For Video And Text Type -->
                    <template v-else-if="section.questionType == 'text_video'">
                      <QuestionTypeTextVideo :data="section" />
                    </template>

                    <!-- For Youtube Link And Text Type -->
                    <template
                      v-else-if="section.questionType == 'text_youtube'"
                    >
                      <QuestionTypeTextYoutubeLink :data="section" />
                    </template>

                    <!-- For Image And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_image'"
                    >
                      <QuestionTypeFreeTextImage :data="section" />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_video'"
                    >
                      <QuestionTypeFreeTextVideo :data="section" />
                    </template>

                    <!-- For Youtube Link And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_youtube'"
                    >
                      <QuestionTypeFreeTextYoutubeLink :data="section" />
                    </template>

                    <!-- For All Type -->
                    <template v-else-if="section.questionType == 'all'">
                      <QuestionTypeAll :data="section" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <draggable v-model="section.assessment" handle=".handle">
          <div
            v-for="(assessment, index) in section.assessment"
            :key="index"
            class="box question question-box"
          >
            <div class="columns is-multiline question-box-answer">
              <!-- For Id Question -->
              <div class="column is-12">
                <b>Question ID : {{ assessment.questionId }}</b>
              </div>

              <!-- For title question -->
              <div class="column is-2">
                <b>#{{ index + 1 }}</b>
              </div>

              <!-- For type question -->
              <div class="column is-10">
                <div class="columns is-12">
                  <div class="column is-8">
                    <!-- For Text Type -->
                    <template v-if="assessment.questionType == 'text'">
                      <QuestionTypeText :data="assessment" />
                    </template>

                    <!-- For Image Type -->
                    <template v-else-if="assessment.questionType == 'image'">
                      <QuestionTypeImage :data="assessment" />
                    </template>

                    <!-- For Video Type -->
                    <template v-else-if="assessment.questionType == 'video'">
                      <QuestionTypeVideo :data="assessment" />
                    </template>

                    <!-- For Youtube Link Type -->
                    <template v-else-if="assessment.questionType == 'youtube'">
                      <QuestionTypeYoutubeLink :data="assessment" />
                    </template>

                    <!-- For Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text'"
                    >
                      <QuestionTypeFreeText :data="assessment" />
                    </template>

                    <!-- For Image And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_image'"
                    >
                      <QuestionTypeTextImage :data="assessment" />
                    </template>

                    <!-- For Video And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_video'"
                    >
                      <QuestionTypeTextVideo :data="assessment" />
                    </template>

                    <!-- For Youtube Link And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_youtube'"
                    >
                      <QuestionTypeTextYoutubeLink :data="assessment" />
                    </template>

                    <!-- For Image And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_image'"
                    >
                      <QuestionTypeFreeTextImage :data="assessment" />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_video'"
                    >
                      <QuestionTypeFreeTextVideo :data="assessment" />
                    </template>

                    <!-- For Youtube Link And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_youtube'"
                    >
                      <QuestionTypeFreeTextYoutubeLink :data="assessment" />
                    </template>

                    <!-- For All Type -->
                    <template v-else-if="assessment.questionType == 'all'">
                      <QuestionTypeAll :data="assessment" />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="columns question-box-answer"
              v-if="
                assessment.type == 'radio-button' ||
                  assessment.type == 'checkbox'
              "
            >
              <div class="column is-2">
                <b>Type</b>
              </div>
              <div class="column is-10">
                {{ assessment.optionRules }}
              </div>
            </div>

            <!-- For answer quetion -->
            <div
              class="columns question-box-answer"
              v-if="assessment.type != 'question'"
            >
              <div class="column is-2">
                <b>Answer</b>
              </div>

              <div class="column is-10">
                <!-- *Note : textarea dan textfield componentnya ditukar sementara -->
                <!-- For Type Textfield -->
                <template v-if="assessment.type == 'textfield'">
                  <AnswerTypeTextarea
                    :data="assessment"
                    :title="title"
                    :indexData="index"
                  />
                </template>

                <!-- For Type Textarea -->
                <template v-else-if="assessment.type == 'textarea'">
                  <AnswerTypeTextfield :data="assessment" :title="title" />
                </template>

                <!-- For Type Multiple Choice -->
                <template v-else-if="assessment.type == 'radio-button'">
                  <AnswerTypeRadioButton
                    :data="assessment"
                    :indexData="index"
                    :title="title"
                    :rulesData="rulesData"
                  />
                </template>

                <!-- For Type Checkbox -->
                <template v-else-if="assessment.type == 'checkbox'">
                  <AnswerTypeCheckbox
                    :data="assessment"
                    :indexData="index"
                    :title="title"
                    :rulesData="rulesData"
                  />
                </template>

                <!-- For Type Linear Scale -->
                <template v-else-if="assessment.type == 'linear-scale'">
                  <AnswerTypeLinearScale :data="assessment" />
                </template>

                <!-- For Type Ranking -->
                <template v-else-if="assessment.type == 'ranking'">
                  <AnswerTypeRanking
                    :data="assessment"
                    :indexData="index"
                    :rankingPointCategory="rulesData.rankingPointCategory"
                  />
                </template>

                <!-- For Type Subsection -->
                <!-- <template v-else-if="assessment.type == 'subsection'">
              <AnswerTypeSubsection :data="assessment" />
            </template>-->
              </div>
            </div>

            <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                <template
                  v-if="assessment.require == 1 || assessment.require == true"
                  >Required</template
                >
                <!-- <template v-else>Not Required</template> -->
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </span>

    <!-- For Modal Assessment Preview -->
    <b-modal
      :active.sync="isModalPreviewActive"
      has-modal-card
      full-screen
      scroll="keep"
    >
      <AssessmentPreview
        :data="data"
        :rulesData="rulesData"
        :type="'detail'"
        :title="title"
      />
    </b-modal>
  </div>
</template>

<script>
// For Package
import draggable from "vuedraggable";

// For Question Vue Component
import QuestionTypeText from "./question-type/QuestionTypeText";
import QuestionTypeImage from "./question-type/QuestionTypeImage";
import QuestionTypeVideo from "./question-type/QuestionTypeVideo";
import QuestionTypeYoutubeLink from "./question-type/QuestionTypeYoutubeLink";
import QuestionTypeFreeText from "./question-type/QuestionTypeFreeText";
import QuestionTypeTextImage from "./question-type/QuestionTypeTextImage";
import QuestionTypeTextVideo from "./question-type/QuestionTypeTextVideo";
import QuestionTypeTextYoutubeLink from "./question-type/QuestionTypeTextYoutubeLink";
import QuestionTypeFreeTextImage from "./question-type/QuestionTypeFreeTextImage";
import QuestionTypeFreeTextVideo from "./question-type/QuestionTypeFreeTextVideo";
import QuestionTypeFreeTextYoutubeLink from "./question-type/QuestionTypeFreeTextYoutubeLink";
import QuestionTypeAll from "./question-type/QuestionTypeAll";

// For Answer Vue Component
import AnswerTypeTextfield from "./answer-type/AnswerTypeTextfield";
import AnswerTypeTextarea from "./answer-type/AnswerTypeTextarea";
import AnswerTypeRadioButton from "./answer-type/AnswerTypeRadioButton";
import AnswerTypeCheckbox from "./answer-type/AnswerTypeCheckbox";
import AnswerTypeLinearScale from "./answer-type/AnswerTypeLinearScale";
import AnswerTypeRanking from "./answer-type/AnswerTypeRanking";
// import AnswerTypeSubsection from "./answer-type/AnswerTypeSubsection";

// For Preview Vude Component
import AssessmentPreview from "../assessment-preview/AssessmentPreview";

export default {
  components: {
    // Using Package
    draggable,

    // Using Question Component
    QuestionTypeText,
    QuestionTypeImage,
    QuestionTypeVideo,
    QuestionTypeYoutubeLink,
    QuestionTypeFreeText,
    QuestionTypeTextImage,
    QuestionTypeTextVideo,
    QuestionTypeTextYoutubeLink,
    QuestionTypeFreeTextImage,
    QuestionTypeFreeTextVideo,
    QuestionTypeFreeTextYoutubeLink,
    QuestionTypeAll,

    // Using Answer Component
    AnswerTypeTextfield,
    AnswerTypeTextarea,
    AnswerTypeRadioButton,
    AnswerTypeCheckbox,
    AnswerTypeLinearScale,
    AnswerTypeRanking,
    // AnswerTypeSubsection

    // Using Preview Component
    AssessmentPreview
  },
  props: {
    data: {
      default: () => {}
    },
    rulesData: {
      default: () => {}
    },
    title: {
      default: () => {}
    }
  },
  data() {
    return {
      isModalPreviewActive: false,
      questionTypes: [
        {
          title: "Text",
          value: "text"
        },
        {
          title: "Image",
          value: "image"
        },
        {
          title: "Video",
          value: "video"
        },
        {
          title: "Text + Image",
          value: "text_image"
        },
        {
          title: "Text + Video",
          value: "text_video"
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.custom-header {
  padding: 1% 1% 1% 1%;
  background-color: #00a3a3;
  color: white;

  &-label {
    // padding-top:1.5%;
    margin: auto;
    color: white;

    p {
      font-weight: 700;
      padding-left: 2%;
    }
  }
}

.question-box {
  padding: 0;

  &-icon {
    padding-top: 1%;

    .column {
      padding-bottom: 0;
    }
  }

  &-answer {
    padding: 1% 0.5% 1% 3%;
  }

  &-level {
    padding: 1% 1% 1% 3%;
    border-top: 0.5px solid #dddddd;
  }
}
</style>
