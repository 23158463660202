<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12"
        v-for="(ranking, indexRanking) in data.rankingOption"
        :key="indexRanking"
      >
        <div class="columns is-multiline">
          <div class="column is-1 answer-input">
            {{ indexRanking + 1 }}
          </div>
          <div class="column is-5 answer-input">
            {{ ranking.option }}
          </div>
          <div class="column is-3 answer-input">
            {{ ranking.rulesTitle[0] }}
          </div>
          <div class="column is-2 answer-input">
            {{ ranking.point }}
          </div>

          <!-- For ranking id -->
          <div class="column is-12 answer-id">
            <b>Answer ID : {{ ranking.rankingId }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.answer {
  &-id {
    padding-top: 0;
    font-size: 14px;
    color: #7e7e7e;
  }

  &-input {
    padding-bottom: 0;
  }
}
</style>
