<template>
  <div>
    <!-- For Free Text -->
    <div v-html="data.questionFreeText"></div>

    <!-- For uploading image -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <!-- For Displaying Image -->
      <template v-if="data.questionFileType == 'image'">
        <img
          v-lazy="getUrlFile(file.url)"
          v-if="file.url != null"
          alt="Image"
        />
      </template>
      <!-- For Displaying Video -->
      <template v-else-if="data.questionFileType == 'video'">
        <video
          v-if="file.url != null"
          width="320"
          height="240"
          controls
          v-lazy="getUrlFile(file.url)"
        ></video>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
  },
}
</script>
