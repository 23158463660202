<template>
  <div>
    <h1 class="title has-text-centered">Rules</h1>
    <div class="box">
      <!-- For Rules Name Field -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Rules Name</b>
        </div>
        <div class="column is-10">{{ data.rulesName }}</div>
      </div>

      <!-- For Random Category -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Random Type</b>
        </div>
        <div
          class="column is-10"
          v-if="
            data.randomType[0] == true &&
            data.randomType[1] == true &&
            data.randomType[2] == true
          "
        >
          Random Question Group, Random Question & Random Option
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == true &&
            data.randomType[1] == true &&
            data.randomType[2] == false
          "
        >
          Random Question Group & Random Question
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == true &&
            data.randomType[1] == false &&
            data.randomType[2] == true
          "
        >
          Random Question Group & Random Option
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == false &&
            data.randomType[1] == true &&
            data.randomType[2] == true
          "
        >
          Random Question & Random Option
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == true &&
            data.randomType[2] == false &&
            data.randomType[2] == false
          "
        >
          Random Question Group
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == false &&
            data.randomType[1] == true &&
            data.randomType[2] == false
          "
        >
          Random Question
        </div>
        <div
          class="column is-10"
          v-else-if="
            data.randomType[0] == false &&
            data.randomType[1] == false &&
            data.randomType[2] == true
          "
        >
          Random Option
        </div>
      </div>

      <!-- For question/question Ggoup to show -->
      <template v-if="!data.randomType[0]">
        <div class="columns is-12">
          <div class="column is-2">
            <b>Question To Show</b>
          </div>
          <div class="column is-10">
            {{ data.randomQuestionNumber }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="columns is-12">
          <div class="column is-2">
            <b>Question Group To Show</b>
          </div>
          <div class="column is-10">
            {{ data.randomQuestionGroupNumber }}
          </div>
        </div>
      </template>

      <!-- For question to display -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Question To Display</b>
        </div>
        <div class="column is-10">
          {{ data.questionToDisplay }}
        </div>
      </div>

      <!-- For time -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Time</b>
        </div>
        <div class="column is-10">
          <template v-if="data.isUseTime"> {{ data.time }} Seconds </template>
          <template v-else> Unlimited </template>
        </div>
      </div>

      <!-- For Question Type -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Question Type</b>
        </div>
        <div class="column is-10">
          <div class="columns">
            <div class="column is-3">{{ data.questionType }}</div>
            <div class="column is-6">
              <template v-if="data.questionType == 'Multiple Choice'"
                >Type : {{ data.multipleChoiceType }}</template
              >
            </div>
          </div>
        </div>
      </div>

      <!-- For Type Point -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Type Point</b>
        </div>
        <div class="column is-10">
          <!-- For Essay Type Point -->
          <div v-if="data.questionType == 'Essay'">
            <div v-for="(typePoint, index) in data.typePoint" :key="index">
              <template v-if="typePoint == 'textfield'">Textfield</template>
              <template v-if="typePoint == 'textarea'">Textarea</template>
            </div>
          </div>

          <!-- For Multiple Choice Type Point -->
          <div v-if="data.questionType == 'Multiple Choice'">
            <div v-for="(typePoint, index) in data.typePoint" :key="index">
              <template v-if="typePoint == 'question'">Question</template>
              <template v-if="typePoint == 'radio-button'"
                >Radio Button</template
              >
              <template v-if="typePoint == 'checkbox'">Checkbox</template>
            </div>

            <div
              class="columns is-12"
              v-if="data.typePoint.includes('checkbox')"
            >
              <div class="column is-2" style="padding-top: 18px">
                Type answer :
              </div>
              <div class="column is-1" style="padding-top: 18px">
                {{ data.checkboxTypeAnswer == "min" ? "Min" : "Max" }}
              </div>
              <div class="column is-0" style="padding-top: 18px">
                <template v-if="data.checkboxTypeAnswer == 'min'">
                  {{ data.checkboxMinAnswer }}
                </template>
                <template v-else-if="data.checkboxTypeAnswer == 'max'">
                  {{ data.checkboxMaxAnswer }}
                </template>
              </div>
              <div class="column is-0" style="padding-top: 18px">Answer</div>
            </div>
          </div>

          <!-- For Other Type Point -->
          <div v-if="data.questionType == 'Other'">
            <div v-for="(typePoint, index) in data.typePoint" :key="index">
              <template v-if="typePoint == 'linear-scale'"
                >Linear Scale</template
              >
              <template v-if="typePoint == 'ranking'">Ranking</template>
            </div>

            <div
              class="columns is-12"
              v-if="data.typePoint.includes('ranking')"
            >
              <div class="column is-2" style="padding-top: 18px">
                Set Ranking Point
              </div>
              <div class="column is-8">
                <div
                  class="columns is-12"
                  v-for="(point, p) in data.rankingPointCategory"
                  :key="p"
                >
                  <div class="column is-2">{{ point.title }}</div>
                  <div class="column is-2">{{ point.point }}</div>
                  <div class="column is-0" style="padding-top: 18px">Point</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
};
</script>
