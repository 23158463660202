<template>
  <div>
    <!-- For Text -->
    {{ data.question }}

    <br />
    <br />

    <!-- For Video -->
    <!-- For Title Video Field -->
    <div class="columns">
      <div class="column is-12">
        <b>Video</b>
      </div>
    </div>

    <!-- For uploading video -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-12">
          <!-- For Displaying Video -->
          <video width="320" height="240" controls>
            <source v-lazy="getUrlFile(file.url)" />
          </video>
        </div>
      </div>
    </div>

    Video Limit: {{ data.questionVideoLimit == true ? 'used' : 'not used' }}
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
  },
}
</script>
