<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-8">
        <template v-if="title == 'Question Test'">
          <b-input type="textarea" disabled></b-input>
        </template>
        <template v-else-if="title == 'Introduction Test'">{{
          data.answer
        }}</template>
      </div>
      <div class="column is-4">Type : {{ data.textRules.type }}</div>

      <template
        v-if="data.textRules.type == 'text' && title == 'Question Test'"
      >
        <!-- For type of minimum or maximum -->
        <div class="column is-3">Type</div>
        <div class="column is-2">
          {{ data.textRules.typeLength == "character" ? "Character" : "Word" }}
        </div>
        <div class="column is-7"></div>

        <!-- For minimum length -->
        <div class="column is-3">Minimum</div>
        <div class="column is-2">
          {{ data.textRules.min }}
        </div>
        <div class="column is-7"></div>

        <!-- For minimum length -->
        <div class="column is-3">Maximum</div>
        <div class="column is-2">
          {{ data.textRules.max }}
        </div>
        <div class="column is-7"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    },
    title: {
      default: () => {}
    }
  }
};
</script>
