<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-3">{{ data.min }}</div>
          <div class="column is-1">to</div>
          <div class="column is-3">{{ data.max }}</div>
        </div>
        <div class="columns">
          <div class="column is-1">
            {{ data.min }}
          </div>
          <div class="column">
            {{ data.labelMin }}
          </div>
        </div>
        <div class="columns">
          <div class="column is-1">
            {{ data.max }}
          </div>
          <div class="column">
            {{ data.labelMax }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
