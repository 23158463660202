<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12"
        v-for="(option, indexOption) in data.option"
        :key="indexOption"
      >
        <div class="columns is-multiline">
          <div class="column is-6 answer-input">
            <template v-if="option.type == 'text'">
              <b-radio type="is-hcc" disabled></b-radio>
              {{ option.title }}
            </template>
            <template v-else-if="option.type == 'image'">
              <b-radio type="is-hcc" disabled></b-radio>
              <img v-lazy="getUrlFile(option.fileUrl)" alt="Image" />
            </template>
          </div>
          <div class="column is-2 answer-input">
            <template v-if="rulesData.multipleChoiceType == 'Tally'">
              {{ option.point }} Point
            </template>
            <template v-else-if="rulesData.multipleChoiceType == 'Pass/Fail'">
              {{ data.answerOption.includes(option.optionId) }}
            </template>
            <template v-else-if="rulesData.multipleChoiceType == 'Coding'">
              {{ option.coding }}
            </template>
          </div>
          <div class="column is-4"></div>

          <!-- For option id -->
          <div class="column is-12 answer-id">
            <b>Answer ID : {{ option.optionId }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
  },
}
</script>

<style lang="scss" scoped>
.answer {
  &-id {
    padding-top: 0;
    font-size: 14px;
    color: #7e7e7e;
  }

  &-input {
    padding-bottom: 0;
  }
}
</style>
